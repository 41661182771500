import './App.css';
import React from "react";
import 'react-bootstrap';
import Home from './Components/Home/Home';
import LoginForm from './Components/Login';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import OldChatContainer from './Components/OldChats/OldChatContainer';
import Settings from './Components/Settings/Settings';
function App() {
  var isAuthenticated;
  if(localStorage.getItem("newlogin"))
  {
    isAuthenticated = true;
  }else{
    isAuthenticated = false;
  }
return (
  <BrowserRouter>
      <Routes>
        <Route path='/' index element={ isAuthenticated ? <Home /> : <Navigate to="/login" replace={true} /> }></Route>
        <Route path='/settings' index element={ isAuthenticated ? <Settings /> : <Navigate to="/login" replace={true} /> }></Route>
        <Route path='/login' index element={ isAuthenticated ? <Navigate to="/" replace={true} /> : <LoginForm /> }></Route>
        <Route path='/chat/:token' element={isAuthenticated ? <OldChatContainer /> : <Navigate to="/login" replace={true} />}></Route>
      </Routes>
  </BrowserRouter> 
  );
}

export default App;
