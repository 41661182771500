import React, { useEffect } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../state/index";

const LeftSidebar = ({ setHideReRes, setHideStopRes, watchNewChat, setwatchNewChat }) => {

  function makethread(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const thread = makethread(20);
  let navigate = useNavigate();
  var indexNo;
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);
  const amount = useSelector(state => state.amount);

  function clearChats() {
    actions.resetStore();
    window.location.replace("/");
  }

  function deleteThread(token) {
    var indexNo;
    for (let i = 0; i < amount.conversations.length; i++) {
      if (amount.conversations[i].hasOwnProperty(token)) {
        indexNo = i;
      }
    }
    actions.deleteThread(indexNo);
    return navigate("/", { state: { thread: makethread(20) } });
  }

  function newChatClick() {
    setwatchNewChat(true);
    navigate("/", { state: { thread: thread, indexNo: indexNo } })
  }

  function logout() {
    localStorage.removeItem("newlogin");
    window.location.reload();
  }
  indexNo = amount.conversations.length;

  return (
    <div className="leftSidebar">
      <div className="newChat">
        <button onClick={() => newChatClick()} type='button' className="newChatButton">
          <p className="plusButton">+</p>
          New Chat
        </button>
      </div>
      <div className="allChats">
        {
          amount.conversations.map(function (thread, index) {
            return (
              Object.keys(thread).map(token => (
                <div key={token}>
                  <Link to={"/chat/" + token} key={token}>
                    <p className="icon">
                      <i className='fa-regular fa-message'></i>
                    </p>
                    {amount.conversations[index][token].title}
                  </Link>
                  <button className='btn btn-transparent text-white thread_trash' onClick={() => deleteThread(token)}>
                    <i className="fa-regular fa-trash-can"></i>
                  </button>
                </div>
              ))
            )
          })
        }
      </div>
      <div className="extras">
        <button className="extraButtons" onClick={clearChats}>
          <p className="icon">
            <i className="fa-regular fa-trash-can"></i>
          </p>
          Clear conversations
        </button>
        <button className="extraButtons" onClick={() => navigate("/settings")}>
          <p className="icon">
            <i className="fa-solid fa-gear"></i>
          </p>
          Settings
        </button>
        <button className="extraButtons mb-0" onClick={() => logout()}>
          <p className="icon">
            <i className="fa-solid fa-right-from-bracket"></i>
          </p>
          Logout
        </button>
      </div>
    </div>
  );
};
export default LeftSidebar;